.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background: var(--color-L100);
  border-radius: 16px;
  padding: 16px;
  margin: 16px 2px 0 2px;
}

.subheading {
  line-height: 18px;
  font-size: 14px;
  color: var(--color-D60);

  @media screen and (min-width: 768px) {
    line-height: 20px;
  }
}
